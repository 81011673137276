<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Operators</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text class="pb-0">
            <v-row no-gutters>
                <v-col cols="12" xl="6" lg="6" md="6">
                    <v-text-field
                        v-model="operator.name"
                        prepend-icon="mdi-account"
                        label="Name"
                        :rules="[rules.required]"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" class="pl-2">
                    <v-combobox
                        v-model="operator.process"
                        prepend-icon="mdi-hammer-wrench"
                        label="Process *"
                        :items="processes"
                        item-text="name"
                        :rules="[rules.required]"
                    >
                    </v-combobox>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" xl="6" lg="6" md="6">
                    <v-text-field
                        v-model="operator.role"
                        prepend-icon="mdi-cog"
                        label="Role"
                        :rules="[rules.required]"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" class="pl-2">
                    <v-text-field
                        v-model="operator.signCode"
                        prepend-icon="mdi-barcode"
                        label="Sign Code"
                        :rules="[rules.required]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row class=" ma-0">
                <v-switch
                    v-model="operator.operatorRole"
                    label="Set Basic Operator View"
                    hide-details
                    :input-value="true"
                    class="my-2"
                ></v-switch>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                @click="saveOperator"
                rounded
                :loading="loading"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff.js'
import cryptoJs from 'crypto-js'

export default {
    name: 'OperatorsForm',
    props: {
        operator: {
            type: Object,
            default: () => ({}),
        },
        originalOperator: {
            type: Object,
            default: () => ({}),
        },
        createForm: {
            type: Boolean,
            default: () => false,
        },
        processesSettings: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        loading: false,
        processes: [],
        rules: {
            required: v => !!v || 'The value is required!',
            number: v =>
                (!!v && typeof Number(v) == 'number') ||
                'The value must be a number',
        },
    }),
    computed: {
        userDiff: function() {
            return objDiff(this.originalOperator, this.operator)
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.processes = await API.getProcesses(this.processesSettings.id)
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async saveOperator() {
            await this.update()
        },
        async update() {
            try {
                this.loading = true
                delete this.userDiff.profilePicURL
                if (this.userDiff.process) {
                    this.userDiff.processData = this.userDiff.process
                    delete this.userDiff.process
                }
                if (this.userDiff.signCode) {
                    const encryptedCode = cryptoJs.AES.encrypt(
                        this.userDiff.signCode,
                        process.env.VUE_APP_ENCRYPTION_PHRASE
                    ).toString()
                    this.userDiff.signCode = encryptedCode
                }
                const {
                    data: { user },
                } = await API.updateUser(this.operator.id, this.userDiff)
                this.$emit('replaceOperator', user)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        deleteSearch() {
            this.search = ''
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
